














































































































































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import 'vue-good-table/dist/vue-good-table.css'
import CheckboxSelector from '@/components/commune/components/filter/CheckboxSelector.vue'
import CustomPagination from '@/components/commune/components/filter/CustomPagination.vue'
import CustomHeader from '@/components/commune/components/filter/CustomHeader.vue'
import { useGetters, useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'
import SwitcherCustom from '@/components/commune/components/filter/SwitcherCustom.vue'
import * as check from '@/composables/commune'

export default defineComponent({
  name: 'table-racc',
  components: {
    CheckboxSelector,
    CustomLink,
    CustomPagination,
    CustomHeader,
    SwitcherCustom,
  },
  props: {
    // Headers array of string
    headers: {
      type: Array as () => Array<string>,
      required: true,
    },
    // date for table and list. /!\ entries must be like headers
    data: {
      type: Array as () => Array<any[]>,
      required: true,
    },
    filter: {
      type: Array as () => Array<string>,
      required: false,
      default() {
        return []
      },
    },
    // Last column to have a button for example
    lastColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, ctx) {
    const search = ref('')
    const { getRoles } = useGetters('user', ['getRoles'])
    let ean: string[] = []
    let type: string[] = []
    let rue: string[] = []
    let status: string[] = []
    const switcher = ref(false)
    const itemsToDisplay = ref(props.data)

    onMounted(() => {
      filterGlobal()
    })

    const onClick = data => {
      ctx.root.$router.push({
        name: 'CommuneRecordSingle',
        params: { id: data.reference },
      })
    }

    // Recupere le emit pour filtrer via les departements
    ctx.root.$on('checkbox:selector:eanTab', data => {
      ean = data
      filterGlobal()
    })
    // Recupere le emit pour filtrer via les roles
    ctx.root.$on('checkbox:selector:type', data => {
      type = data
      filterGlobal()
    })
    ctx.root.$on('checkbox:selector:rue', data => {
      rue = data
      filterGlobal()
    })
    ctx.root.$on('checkbox:selector:status', data => {
      status = data
      filterGlobal()
    })
    ctx.root.$on('switcher:racc', data => {
      switcher.value = data
      filterGlobal()
    })
    // Filtrage du tableau
    const filterGlobal = () => {
      itemsToDisplay.value = props.data
      if (
        ean.length < 1 &&
        type.length < 1 &&
        rue.length < 1 &&
        status.length < 1
      ) {
        itemsToDisplay.value = props.data
      }
      itemsToDisplay.value = itemsToDisplay.value.filter(
        x => x.cloture === switcher.value
      )
      if (ean.length > 0) {
        itemsToDisplay.value = itemsToDisplay.value.filter(x =>
          ean.includes(x.ean)
        )
      }
      if (rue.length > 0) {
        itemsToDisplay.value = itemsToDisplay.value.filter(x =>
          rue.includes(x.rue)
        )
      }
      if (type.length > 0) {
        itemsToDisplay.value = itemsToDisplay.value.filter(x =>
          type.includes(x.demande)
        )
      }
      if (status.length > 0) {
        itemsToDisplay.value = itemsToDisplay.value.filter(x =>
          status.includes(x.status)
        )
      }
    }

    // Fonction pour retrouver un element dans un tableau
    // const findOne = (haystack, arr) => arr.some(v => haystack.indexOf(v) >= 0)
    const permissions = () => {
      if (
        getRoles.value.includes('RACC_GERER') ||
        getRoles.value.includes('ADMIN')
      ) {
        return true
      }

      return false
    }

    const change = () => {
      ctx.root.$emit('reset-pagination')
    }

    return {
      search,
      onClick,
      status,
      type,
      rue,
      ean,
      itemsToDisplay,
      ...useState('commune', ['i18n']),
      check,
      permissions,
      switcher,
      change,
    }
  },
})
