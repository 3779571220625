var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"commune-record pb-xxl"},[_c('header',[_c('breadcrumb',{attrs:{"content":_vm.breadcrumb}}),_c('div',{staticClass:"wrapper"},[(_vm.errorRecord)?_c('message-record',{attrs:{"color":'error',"label":_vm.i18n.error.racc,"type":'record',"value":_vm.errorRecord}}):_vm._e(),(_vm.i18n.communeRaccordement.picture)?_c('img',{staticClass:"page-header-image",attrs:{"src":_vm.apiDomain + _vm.i18n.communeRaccordement.picture.url,"alt":""}}):_c('img',{staticClass:"page-header-image",attrs:{"alt":"","src":require("../../../assets/svg/commune/ico-raccordements.svg")}})],1),_c('div',{staticClass:"page-header wrapper"},[_c('div',{staticClass:"page-header__title"},[_c('h1',{staticClass:"main-title",domProps:{"innerHTML":_vm._s(_vm.i18n.communeRaccordement.title)}}),(_vm.i18n.communeRaccordement.optionHtml.displayContent.length > 0)?_c('div',{staticClass:"mt-xs mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.communeRaccordement.optionHtml.htmltext)}}):_vm._e()]),_c('div',{staticClass:"page-header__actions"},[_c('PointContact'),(!_vm.isLoading && _vm.permissions())?[_c('custom-link',{staticClass:"add-record",attrs:{"content":{
              label: _vm.i18n.communeRaccordement.labelButton,
              url: _vm.demandeRaccordement,
              target: '_blank',
            },"modifiers":[
              'btn',
              'green',
              'white',
              'big',
              'rectrounded',
              'uppercase' ],"type":"externalLink"}})]:_vm._e()],2)])],1),(_vm.dataList && _vm.columns)?_c('div',{staticClass:"wrapper"},[(!_vm.isLoading)?[_c('Table',{attrs:{"data":_vm.dataList,"headers":_vm.columns,"lastColumn":true}})]:_c('g-loader',{staticClass:"users-loader mt-s",attrs:{"modifiers":['big']}})],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }