


























































































































































































































import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import Message from '@/components/ui/Message.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import Table from '@/views/commune/record/TableRacc.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import * as check from '@/composables/commune'
import MessageRecord from '@/components/commune/components/filter/Message.vue'
import PointContact from '@/components/commune/components/PointContact.vue'

const { VUE_APP_DOMAIN } = process.env
export default defineComponent({
  name: 'commune-record',
  components: {
    Message,
    CustomLink,
    Table,
    Breadcrumb,
    Header,
    MessageRecord,
    PointContact,
  },
  setup(_props, ctx) {
    const message = ref(true)
    const { i18n } = useState('commune', ['i18n'])
    const { FETCH_RECORDS } = useActions('commune', ['FETCH_RECORDS'])
    const dataList = ref()
    const breadcrumb = [
      {
        label: i18n.value.communeRaccordement.title?.replace(/<[^>]*>/g, ''),
        url: '',
      },
    ]
    const isLoading = ref(false)
    const errorRecord = ref(false)
    const { getRoles } = useGetters('user', ['getRoles'])
    const { currentLang } = useGetters(['currentLang'])
    const demandeRaccordement = ref(
      `${VUE_APP_DOMAIN}/fr/nos-services/raccordement-travaux/nouveau-raccordement/formulaire-nouveau/`
    )
    watch(currentLang, old => {
      if (currentLang.value) {
        // eslint-disable-next-line max-len
        demandeRaccordement.value = `${VUE_APP_DOMAIN}/fr/nos-services/raccordement-travaux/nouveau-raccordement/formulaire-nouveau/`
      } else {
        // eslint-disable-next-line max-len
        demandeRaccordement.value = `${VUE_APP_DOMAIN}/de/unsere-leistungen/anschluss-und-arbeiten/neuer-anschlussantrag/neuer-formular/`
      }
    })

    onBeforeMount(() => {
      if (ctx.root.$route.params.data === 'errorRecord') {
        errorRecord.value = true
      }
      isLoading.value = true
      FETCH_RECORDS()
        .then(resp => {
          dataList.value = check.getRaccList()
          isLoading.value = false
        })
        .catch(() => {
          isLoading.value = false
        })
    })

    const columns = [
      {
        label: i18n.value.racc.address,
        field: 'adresse',
        thClass: 'adresse',
        tdClass: 'adresse',
      },
      {
        label: i18n.value.racc.type,
        field: 'demande',
        type: 'string',
        thClass: 'demande',
        tdClass: 'demande',
      },
      {
        label: i18n.value.racc.reference,
        field: 'reference',
        type: 'string',
        thClass: 'reference',
        tdClass: 'reference',
      },
      {
        label: i18n.value.racc.status,
        field: 'status',
        type: 'string',
        thClass: 'status',
        tdClass: 'status',
      },
      {
        label: '',
        field: 'btn',
        html: true,
        globalSearchDisabled: true,
        sortable: false,
        thClass: 'actions',
        tdClass: 'actions',
      },
    ]

    const permissions = () => {
      if (
        getRoles.value.includes('RACC_GERER') ||
        getRoles.value.includes('ADMIN')
      ) {
        return true
      }

      return false
    }

    ctx.root.$on('popup:confirm:close', () => {
      errorRecord.value = false
    })

    return {
      message,
      ...useState('user', ['firstname', 'lastname', 'commune']),
      i18n,
      columns,
      dataList,
      breadcrumb,
      isLoading,
      errorRecord,
      permissions,
      demandeRaccordement,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
