




























import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'switcher-custom',
  components: {},
  props: {
    checked: Boolean,
    disabled: Boolean,
    color: {
      type: String,
      required: false,
      default: 'green',
    },
    emitText: {
      type: String,
      required: true,
    },
    firstText: {
      type: String,
      required: false,
    },
    secondText: {
      type: String,
      required: false,
    },
    styleFirstText: {
      type: String,
      required: false,
    },
    styleSecondText: {
      type: String,
      required: false,
    },
  },

  setup(props, ctx) {
    const switcher = ref(true)
    const emit = () => {
      ctx.root.$emit(props.emitText, switcher.value)
    }

    return { emit, switcher }
  },
})
